import React from "react";
import ReactPlayer from "react-player";

function Video(props: any) {
  const handleOnEnd = () => {
    props.setisFinished(true);
  };
  console.log(props.url);
  return (
    <>
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url={props.url}
        onEnded={handleOnEnd}
        controls={true}
      ></ReactPlayer>
    </>
  );
}

export default Video;
