import React, { useCallback, useEffect, useState } from "react";
import "./ProgessBar.css";
import { Progress } from "@nextui-org/react";
function ProgessBar(props: any) {
  const data = [{ done: true }, { done: true }, { done: false }];
  return (
    <div className="progressBar_container">
      {data.map((p, i) => {
        if (i === data.length - 1) {
          return <div className={p.done ? "done progess" : "progess"}></div>;
        } else {
          return (
            <>
              <div className={p.done ? "done progess" : "progess"}></div>
              <div className="vl"></div>
            </>
          );
        }
      })}
    </div>
  );
}

export default ProgessBar;

export function ProgessBarHorizontal(props: any) {
  const [progressBarStatus, setprogressBarStatus] = useState([]);
  const { stepActual, steps } = props;
  console.log(props);
  useEffect(() => {
    let aux: any = [];
    for (let i = 0; i <= steps - 1; i++) {
      if (i > stepActual) {
        aux.push({
          done: false,
        });
      } else {
        aux.push({
          done: true,
        });
      }
    }
    setprogressBarStatus(aux);
  }, [stepActual]);

  const data = [{ done: true }, { done: true }, { done: false }];
  return (
    <div style={{ position: "relative" }} className="progressBar">
      <Progress
        color="primary"
        size="xl"
        title="1000"
        value={(stepActual / progressBarStatus.length) * 100}
      />
      <div
        style={{ position: "absolute", top: "0px", left: "50%" }}
        className=" w-100"
      >
        <span className="processTitle">
          {stepActual}/{progressBarStatus.length}
        </span>
      </div>
    </div>
  );
}
