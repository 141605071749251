import axios from "axios";
import { compare } from "../utils/utils";

export const findInterview = (id_interview: String, token: any) => {
  const configSecurity = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get(
    process.env.REACT_APP_BACKEND +
      "/preguntasPorEntrevista?id_entrevista=" +
      id_interview,
    configSecurity
  );
};

export const saveQuestion = (
  base64: string,
  user: any,
  data: any,
  statusIndex: number,
  token: any
) => {
  const configSecurity = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    process.env.REACT_APP_BACKEND + "/guardarAudios",
    {
      id_interview: user.id_interview,
      id_question: data.questions.sort(compare)[statusIndex].id,
      audio: base64.substring(22, base64.length),
    },
    configSecurity
  );
};
export const saveQuestionTest = (
  user: any,
  id_question: any,
  id_answer: any,
  token: any
) => {
  const configSecurity = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    process.env.REACT_APP_BACKEND + "/guardarPreguntasTipoTest",
    {
      id_interview: user.id_interview,
      id_test_question: id_question,
      id_test_possible_answer: id_answer,
    },
    configSecurity
  );
};
export const aceptarGRDP = (
  id_entrevista: number,
  token: any
) => {
  const configSecurity = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post (
    process.env.REACT_APP_BACKEND + "/aceptarGRDP/"+ id_entrevista,
    {

    },
    configSecurity
  );
}