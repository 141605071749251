import { userInfo } from "os";
import * as React from "react";
function Header(props: any) {
  return (
    <div className="header">
      <div>
        <div className="pl-2 h-100 w-100 imgContainer">
          <img
            className=" imgLogo"
            src={
              process.env.REACT_APP_BACKEND +
              "/obtenerLogo?id_proceso=" +
              props.user.id_process
            }
          ></img>
        </div>
      </div>
      <div className="h-100  w-100 d-flex justify-content-center align-items-center text-center">
        <h1 className="processTitle">{props.user.process_name}</h1>
      </div>
      <div style={{ width: "40px" }}></div>
    </div>
  );
}

export default Header;
