import React, { useEffect, useState } from "react";

import MicRecorder from "mic-recorder-to-mp3";
import ReactStopwatch from "react-stopwatch";
import { byteCountCheck } from "../../utils/utils";
import { toast } from "react-toastify";

const Mp3Recorder = new MicRecorder({
  bitRate: 64,
  prefix: "data:audio/wav;base64,",
});

function Record(props) {
  const [record, setRecord] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [recordStatus, setRecordStatus] = useState(null);

  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  }, []);
  const startRecording = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setRecord(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setRecord(false);
        console.log(blobURL);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64String = reader.result;

          if (byteCountCheck(base64String)) {
            props.onFinish(base64String);
          } else {
            toast.error("Ha habido un problema a procesar su audio");
          }

          // Simply Print the Base64 Encoded String,
          // without additional data: Attributes.
          console.log(
            "Base64 String without Tags- ",
            base64String.substr(base64String.indexOf(", ") + 1)
          );
        };
      })
      .catch((e) => console.log(e));
    console.log("stop");
  };

  if (!props.isFinished) {
    return <></>;
  }
  console.log(
    "tessssst",
    "00:" +
      (props.data.max_min_answer.toString().length === 1
        ? "0" + props.data.max_min_answer
        : props.data.max_min_answer) +
      ":00"
  );
  return (
    <>
      {record ? (
        <button onClick={stopRecording} className="stopButton">
          <ReactStopwatch
            seconds={0}
            minutes={0}
            hours={0}
            limit={
              "00:" +
              (props.data.max_min_answer.toString().length === 1
                ? "0" + props.data.max_min_answer
                : props.data.max_min_answer) +
              ":00"
            }
            onChange={({ hours, minutes, seconds }) => {
              // do something
            }}
            onCallback={stopRecording}
            render={({ formatted, hours, minutes, seconds }) => {
              return (
                <div>
                  {minutes.toString().length === 1 ? "0" + minutes : minutes}:
                  {seconds.toString().length === 1 ? "0" + seconds : seconds}
                </div>
              );
            }}
          />
          STOP
        </button>
      ) : (
        <button onClick={startRecording} className="buttonRec">
          <img
            style={{ width: "25px", height: "25px" }}
            src="microphone.svg"
          ></img>
        </button>
      )}
    </>
  );
}

export default Record;
