import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header/Header";
import PrivacyPolicies from "./Components/PrivacyPolicies/PrivacyPolicies";
import jwt_decode from "jwt-decode";

import "react-toastify/dist/ReactToastify.css";

import { Switch, Route, useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import { findInterview } from "./Service/Interview";
import Interview from "./Components/Interview/Interview";
import TestQuestions from "./Components/TestQuestions/TestQuestions";
import { aceptarGRDP } from "./Service/Interview";
// import PrivacyPolicies from "./Components/PrivacyPolicies";

function App() {
  return (
    <>
      <ToastContainer></ToastContainer>
      {/* <TestQuestions></TestQuestions> */}
      <Switch>
        <Route exact path={"/PrivacyPolicies"}>
          <PrivacyPolicies></PrivacyPolicies>
        </Route>
        <Route exact path={"/:id"}>
          <Main></Main>
        </Route>
        {/*<Route path={"/:PODP"} component={PrivacyPolicies}></Route>*/}
      </Switch>
    </>
  );
}

export default App;

function Main(props: any) {
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [isStarted, setisStarted] = useState<boolean>(false);
  const myRef: any = useRef();
  const myRef2: any = useRef();
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsCkecked] = useState(false);
  const id: any = useParams();
  useEffect(() => {
    if (isLoading) {
      try {
        let decode: any = jwt_decode(id.id);
        console.log(decode);
        let expDate = new Date(decode.exp * 1000);
        console.log(expDate);
        if (expDate <= new Date()) {
          toast.error("Entrevista Caducada");
        } else {
          setUser(decode);
          findInterview(decode.id_interview, id.id)
            .then((response: any) => {
              setIsLoading(false);
              setData(response.data);
            })
            .catch((error) => {
              if (error.response.status == 404) {
                toast.error("No esta disponible esta entrevista");
                setError(true);
              } else {
                toast.error("Ha habido un problema");
              }
            });
        }
      } catch (exception: any) {
        if (
          exception.message ===
          "Invalid token specified: Cannot read properties of undefined (reading 'replace')"
        ) {
          toast.error("No esta disponible esta entrevista");
          setError(true);
          setIsLoading(false);
        }
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <ReactLoading
          type={"cylon"}
          color={"#ffed1c"}
          height={"10%"}
          width={"10%"}
        />
      </div>
    );
  }
  if (error) {
    return <h1>No existe una entrevista para este URL</h1>;
  }
  console.log("user", user);
  function handleCheck() {
    if (isChecked === true) {
      setIsCkecked(false);
    } else {
      setIsCkecked(true);
      aceptarGRDP(user.id_interview, id.id);
    }
  }
  return (
    <div className="d-flex flex-column">
      <link
        rel="stylesheet"
        type="text/css"
        href={`/style/${user.id_process}.css`}
      />
      <Header user={user}></Header>
      <div
        className="main_container"
        style={
          !isStarted
            ? {
                height: "calc(100% - 159px)",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }
            : {}
        }
      >
        {isStarted ? (
          <Interview
            myRef={myRef}
            myRef2={myRef2}
            user={user}
            data={data}
            token={id.id}
          ></Interview>
        ) : (
          <div className="start_container">
            <div className="start_box">
              <h1 className="welcome_title">!Hola {user.candidate_name}!</h1>
              <p>
                Muchas gracias por aceptar la entrevista. Una vez que este
                preparado, pulsa el siguiente boton para comenzar con su
                entrevista ¡Suerte!
              </p>
              <label className="checkbox_label">
                <input
                  onClick={handleCheck}
                  className="checkbox_input"
                  type="checkbox"
                  id="cbox1"
                  value="first-checkbox"
                />
                He leído y acepto la{" "}
                <Link to={"/PrivacyPolicies"}>Política de Privacidad</Link>
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  disabled={!isChecked}
                  onClick={() => setisStarted(true)}
                  className="start_button"
                >
                  Empezar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Fotter myRef2={myRef2} user={user}></Fotter>
    </div>
  );
}

function Fotter(props: any) {
  console.log(props);
  return (
    <div ref={props.myRef2} className="col-12 footer_container">
      <div>{props.user.company_name}</div>
      <div>{props.user.phone_number}</div>
      <div>{props.user.mail}</div>
    </div>
  );
}
