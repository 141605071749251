export function byteCountCheck(s: string) {
  let size = encodeURI(s).split(/%..|./).length - 1;
  if (size <= 100000) {
    return false;
  } else {
    return true;
  }
}

export function compare(a: any, b: any) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  // a debe ser igual b
  return 0;
}
