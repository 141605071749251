import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { ProgessBarHorizontal } from "../ProgessBar/ProgessBar";
import Record from "../Record/Record";
import Video from "../Video/Video";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { saveQuestion, saveQuestionTest } from "../../Service/Interview";
import { compare } from "../../utils/utils";
import TestQuestions from "../TestQuestions/TestQuestions";

function Interview(props: any) {
  const { data, user } = props;
  const [isFinished, setisFinished] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [testAnswer, setTestAnswer] = useState(null);
  const [height, setHeight] = useState<number>(0);
  const [statusIndex, setStatusIndex] = useState<number>(
    data?.last_question_with_answer === 0
      ? 0
      : data?.last_question_with_answer + 1
    // 0
  );
  const size: any = useWindowSize();

  useEffect(() => {
    setStatusIndex(
      data?.last_question_with_answer === 0
        ? 0
        : data?.last_question_with_answer + 1
      //0
    );
  }, [data]);

  const handleTestQuestion = () => {
    setisLoading(true);
    saveQuestionTest(
      user,
      data.test_questions[statusIndex - 1].id,
      testAnswer,
      props.token
    )
      .then(() => {
        setStatusIndex(statusIndex + 1);
        setisLoading(false);
        setTestAnswer(null);
      })
      .catch(() => {
        toast.error("Ha habido un problema");
      });
  };
  const onFinish = (base64: any) => {
    console.log("Terminado");
    setisLoading(true);
    setisFinished(false);

    if (base64) {
      saveQuestion(
        base64,
        user,
        data,
        statusIndex - data.test_questions.length,
        props.token
      )
        .then((response) => {
          setStatusIndex(statusIndex + 1);
          setisLoading(false);
        })
        .catch(() => {
          toast.error("Ha habido un problema");
        });
    } else {
      setStatusIndex(statusIndex + 1);
      setisLoading(false);
    }
  };
  useEffect(() => {
    setHeight(
      props.myRef2?.current?.offsetTop -
        (props.myRef?.current?.offsetTop + props.myRef?.current?.offsetHeight)
    );
  }, [
    props.myRef2?.current?.offsetTop,
    props.myRef?.current?.offsetTop,
    props.myRef?.current?.offsetHeight,
  ]);
  console.log(height);
  useEffect(() => {
    if (
      isFinished &&
      data.questions.sort(compare)[statusIndex]?.type === "Bienvenida"
    ) {
      onFinish(null);
    }
  }, [isFinished]);

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <ReactLoading
          type={"cylon"}
          color={"#ffed1c"}
          height={"10%"}
          width={"10%"}
        />
      </div>
    );
  }

  console.log(
    "ddd",
    data.questions.sort(compare)[statusIndex - data.test_questions.length]
  );
  return (
    <>
      <div className="col-12 d-flex justify-content-center mt-2">
        <ProgessBarHorizontal
          steps={data.questions.length + data.test_questions.length}
          // steps={11}
          stepActual={statusIndex}
        ></ProgessBarHorizontal>
      </div>

      <div
        style={{
          height: size.width < 600 ? "38vh" : "60vh",
          marginTop: "20px",
        }}
        className="col-12"
        ref={props.myRef}
      >
        {statusIndex === 0 ? (
          <Video
            url={data.questions.sort(compare)[statusIndex].url ?? ""}
            setisFinished={setisFinished}
          ></Video>
        ) : statusIndex <= data.test_questions.length ? (
          <TestQuestions
            setTestAnswer={setTestAnswer}
            question={data.test_questions[statusIndex - 1].question}
            ids_answers={data.test_questions[statusIndex - 1].ids_answers}
          ></TestQuestions>
        ) : (
          <Video
            url={
              data.questions.sort(compare)[
                statusIndex - data.test_questions.length
              ].url ?? ""
            }
            setisFinished={setisFinished}
          ></Video>
        )}
      </div>
      {data.questions.sort(compare)[statusIndex]?.type === "Bienvenida" ||
      data.questions.sort(compare)[statusIndex - data.test_questions.length]
        ?.type === "Despedida" ? (
        <></>
      ) : (
        <div
          className="col-12 container_rec"
          style={{
            height: height,
          }}
        >
          <Record
            onFinish={onFinish}
            isFinished={isFinished}
            data={props.data}
          ></Record>
        </div>
      )}
      {statusIndex <= data.test_questions.length &&
      statusIndex != 0 &&
      testAnswer ? (
        <div className="container_finish">
          <button
            onClick={() => {
              handleTestQuestion();
            }}
            className="start_button"
          >
            Siguente pregunta
          </button>
        </div>
      ) : (
        <></>
      )}
      {data.questions.sort(compare)[statusIndex - data.test_questions.length]
        ?.type === "Despedida" ? (
        <div className="container_finish">
          <button
            onClick={() => {
              console.log("Cierre");
              window.location.href = "https://iellow.io/";
            }}
            className="start_button"
          >
            Salir
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Interview;
