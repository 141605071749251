const PrivacyPolicies = () => {
  return (
    <section className="privacyPolicies">
      <div className="privacyPolicies__containerLogo"><img className="privacyPolicies__logo" src="./Assets/logo.svg" alt="" /></div>
      <h2 className="privacyPolicies__title">POLÍTICA DE PRIVACIDAD</h2>
      <p className="privacyPolicies__paragraph">
        IE TEAM S.L. es el Responsable del tratamiento de los datos personales
        del Interesado y te informa de que estos datos se tratarán de
        conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de
        abril (RGPD), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por
        interés legítimo del Responsable, facilitándote la siguiente información
        del tratamiento.
      </p>
      <p className="privacyPolicies__paragraph">
        Mediante los formularios de registro del presente sitio web o el envío
        de correo electrónico se recogen y tratan sus datos personales, el
        tratamiento de los cuales se rige por la presente Política de
        Privacidad. Estos datos son necesarios para la gestión y mantenimiento
        de los servicios prestados.
      </p>
      <p className="privacyPolicies__paragraph">
        Nuestro compromiso además supone explicarte como interesado lo
        siguiente:
      </p>
      <ul>
        <li className="item" >
          • Se recogen tus datos para que la experiencia de usuario mejore,
          atendiendo a tus intereses y necesidades.
        </li>
        <li className="item" >
          • Somos transparentes en relación a los datos que obtenemos acerca de
          ti y la razón por la que lo hacemos.
        </li>
        <li className="item" >
          • Nuestra intención es ofrecerte la mejor experiencia posible. Por
          ello, cuando vayamos a usar tu información personal lo haremos siempre
          cumpliendo la normativa, y cuando sea necesario, solicitaremos tu
          consentimiento.
        </li>
        <li className="item" >
          • Entendemos que tus datos te pertenecen. Por tanto, si decides no
          autorizarnos a procesarlos puedes solicitarnos que dejemos de
          tratarlos.
        </li>
        <li className="item" >
          • Nuestra prioridad consiste en garantizar tu seguridad y tratar tus
          datos de acuerdo con la normativa europea. IETEAM S.L. adopta las
          medidas necesarias para garantizar la seguridad, integridad y
          confidencialidad de los datos conforme a lo establecido en la
          legislación vigente.
        </li>
      </ul>
      <h3 className="privacyPolicies__subtitle">¿Qué datos personales recopilamos?</h3>
      <p className="privacyPolicies__paragraph" >Los datos personales que el usuario puede llegar a proporcionar:</p>
      <ul>
        <li className="item" >• Nombre, dirección y fecha de nacimiento.</li>
        <li className="item" >• Número de teléfono y dirección de correo electrónico.</li>
        <li className="item" >• Ubicación.</li>
        <li className="item" >• Información relativa a experiencia profesional (currículum)</li>
        <li className="item" >
          • Dirección IP, fecha y hora en la que has accedido a nuestros
          servicios, navegador de internet que uses y datos sobre el sistema
          operativo del dispositivo.
        </li>
      </ul>
      <p className="privacyPolicies__paragraph" >
        Cualquier otra información o datos que decidas compartir con nosotros.
      </p>
      <p className="privacyPolicies__paragraph" >
        Como interesado aceptas proporcionar información completa y correcta en
        todo momento a IETEAM S.L., respondiendo, en cualquier caso, de la
        veracidad de los datos facilitados, reservándose IETEAM S.L. el derecho
        a excluir de los servicios prestados a toda persona que haya facilitado
        datos falsos, incompletos, inexactos o no estén actualizados, sin
        perjuicio de las demás acciones legales que pudieran ejercitarse.
      </p>
      
      <p className="privacyPolicies__paragraph" ><span className="boldText" >Fin del tratamiento: </span>
        hacerte partícipe como interesado en los procesos de selección de
        personal mediante una entrevista de trabajo y valoración de tu
        currículum, llevando a cabo un análisis de tu perfil como solicitante y
        permitiendo que el mismo sea vinculado a ofertas de trabajo que se
        ajusten a tu perfil y preferencias.
      </p>
      
      <p className="privacyPolicies__paragraph" ><span className="boldText" >Legitimación del tratamiento: </span>consentimiento explícito del interesado (art. 6.1.a GDPR).</p>
      <h3 className="privacyPolicies__subtitle" >¿Cuánto tiempo conservamos tus datos?</h3>
      <p className="privacyPolicies__paragraph" >
        Se conservarán mientras dure la relación comercial y profesional
        surgida, o mientras resulten necesarios para la finalidad específica y
        concreta del tratamiento y hasta que no se solicite su supresión por el
        interesado. Transcurrido el plazo se procederá a la supresión de los
        datos garantizándole un total respeto a la confidencialidad, tanto en el
        tratamiento como en su posterior destrucción.
      </p>
      
      <p className="privacyPolicies__paragraph" ><span className="boldText" >Actualización de los datos: </span>
        en caso de producirse alguna modificación en sus datos, te rogamos nos
        lo comuniques por escrito lo antes posible, con objeto de mantener tus
        datos debidamente actualizados.
      </p>
      <h3 className="privacyPolicies__subtitle" >¿A qué destinatarios se comunicarán los datos?</h3>
      <p className="privacyPolicies__paragraph" >
        Con carácter general no comunicará estos datos personales a terceros,
        con la salvedad de que la prestación de un servicio implique la
        necesidad de una relación contractual con un encargado de tratamiento y
        ello sea estrictamente necesario para gestionar y mantener la relación
        entre IETEAM S.L. y el usuario. Esto se hará sólo durante el tiempo
        imprescindible para posibilitar la ejecución del contrato de encargo, y
        en las mismas condiciones y con las mismas responsabilidad que se le
        exigen al responsable. Una vez finalizado el encargo, el encargado del
        tratamiento devolverá al Responsable los datos personales y suprimirá
        cualquier copia de la que disponga.
      </p>
      <p className="privacyPolicies__paragraph" ><span className="boldText" >Comunicación de los datos: </span>no se comunicarán los datos a terceros, salvo obligación legal.</p>
      <h3 className="privacyPolicies__subtitle" >
        ¿Qué medidas de seguridad aplicamos para proteger tus datos personales?
      </h3>
      <p className="privacyPolicies__paragraph" >
        IETEAM S.L. ha adoptado los niveles de seguridad de protección de los
        Datos Personales legalmente requeridos, y procura instalar aquellos
        otros medios y medidas técnicas adicionales a su alcance para evitar la
        pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos
        Personales facilitados a IE TEAM.
      </p>
      <p className="privacyPolicies__paragraph" >
        IETEAM S.L. no es responsable de hipotéticos daños o perjuicios que se
        pudieran derivar de interferencias, omisiones, interrupciones, virus
        informáticos, averías telefónicas o desconexiones en el funcionamiento
        operativo de este sistema electrónico, motivadas por causas ajenas a
        IETEAM S.L.; de retrasos o bloqueos en el uso del presente sistema
        electrónico causados por deficiencias o sobrecargas de líneas
        telefónicas o sobrecargas en el Centro de Procesos de Datos, en el
        sistema de Internet o en otros sistemas electrónicos, así como de daños
        que puedan ser causados por terceras personas mediante intromisiones
        ilegítimas fuera del control de IETEAM S.L.. Ello no obstante, el
        usuario debe ser consciente de que las medidas de seguridad en Internet
        no son inexpugnables.
      </p>
      <h3 className="privacyPolicies__subtitle" >¿Qué derechos te asisten y cómo puedes ejercerlos?</h3>
      <p className="privacyPolicies__paragraph" >
        Cualquier persona tiene derecho a obtener confirmación sobre si en
        IETEAM S.L. estamos tratando datos personales que les conciernan, o no.
      </p>
      <p className="privacyPolicies__paragraph" >
        Como titular de los datos tienes derecho a acceder a tus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
      </p>
      <p className="privacyPolicies__paragraph" >
        En determinadas circunstancias, como interesado podrás solicitar la
        limitación del tratamiento de tus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones.
      </p>
      <p className="privacyPolicies__paragraph" >
        En determinadas circunstancias y por motivos relacionados con tu
        situación particular, podrás oponerte al tratamiento de tus datos.
        IETEAM S.L. dejará de tratar los datos, salvo por motivos legítimos
        imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
      </p>
      <p className="privacyPolicies__paragraph" >El usuario tendrá derecho a la portabilidad, es decir, a que los datos personales que hubiera facilitado, se transmitan directamente a otro responsable en formato estructurado, de uso común y lectura mecánica, cuando técnicamente fuera posible.</p>
      <p className="privacyPolicies__paragraph" >El titular de los datos, o su representante legal, acreditando su identidad puede ejercer los referidos derechos dirigiendo un email a la dirección electrónica IETEAM S.L.@IETEAM S.L..es o mediante correo postal a: Parque Empresarial Zuatzu. Edificio Europa Planta 5, Local 3 C.P. 20018 Donostia-San Sebastián (Gipuzkoa) Teléfono (+34) 943 536 124</p>
      <p className="privacyPolicies__paragraph" >Puedes obtener información adicional acerca de tus derechos ante la Agencia Española de Protección de Datos en http://www.aepd.es o la autoridad correspondiente del país en el que te encuentres. Cuando el titular de los derechos no haya obtenido satisfacción en el ejercicio de los mismos, puede reclamar la asistencia de la Agencia Española de Protección de Datos (o de la autoridad correspondiente) para que el ejercicio de sus derechos sea efectivo, mediante la presentación de una Reclamación de Tutela de Derechos (http://www.aepd.es)</p>
    <h3 className="privacyPolicies__subtitle" >¿Dónde se almacenan tus datos?</h3>
    <p className="privacyPolicies__paragraph" >Con carácter general, los datos se almacenan dentro de la UE. Los datos que se envíen a terceros que no pertenezcan a la UE, nos aseguraremos que ofrezcan un nivel de protección suficiente.</p>
    <h3 className="privacyPolicies__subtitle" >¿Puede modificarse la política de privacidad?</h3>
    <p className="privacyPolicies__paragraph" >Esta política de privacidad puede modificarse. Te recomendamos que revises la política de privacidad con cierta periodicidad.</p>
    <p className="privacyPolicies__paragraph" >El Interesado consiente el tratamiento de sus datos en los términos expuestos.</p>
    </section>
  );
};

export default PrivacyPolicies;
