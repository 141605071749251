import { Checkbox } from "@nextui-org/react";
import { useState } from "react";

function TestQuestions(props: any) {
  const [selected, setSelected] = useState();
  const handleChange = (e: any) => {
    setSelected(e);
    props.setTestAnswer(e);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="test_question_title">{props.question}</h1>

      <div style={{ display: "flex", flexDirection: "column" }}>
        {props.ids_answers.map((answer: any) => {
          return (
            <Checkbox
              size="sm"
              value={answer.id}
              onChange={() => handleChange(answer.id)}
              isSelected={selected === answer.id}
            >
              {answer.answer}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
}

export default TestQuestions;
